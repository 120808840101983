
// @ is an alias to /sr
import { computed, defineComponent, inject, reactive, toRefs } from "vue";
import AfterLoginPageLayout from "@/views/layouts/AfterLoginPageLayout.vue";

import FlatButton from "@/components/atomics/FlatButton.vue";
import TextButton from "@/components/atomics/TextButton.vue";
import StepProgressBar from "@/components/atomics/StepProgressBar.vue";
import HeaderTitleBar from "@/components/parts/HeaderTitleBar.vue";
import { useI18n } from "vue-i18n";
import { useRoute, useRouter } from "vue-router";
import { useStore } from "vuex";
import useContract from "./contract";

export default defineComponent({
  name: "ContractDone",
  components: {
    FlatButton,
    TextButton,
    StepProgressBar,
    HeaderTitleBar,
    AfterLoginPageLayout,
  },
  setup() {
    const { t } = useI18n();
    const state = reactive({
      isShownMenuOption: false,
      progressItems: [
        {
          name: t("template.progressAddFile"),
        },
        {
          name: t("template.progressSigner"),
        },
        {
          name: t("template.progressVars"),
        },
        {
          name: t("contract.progressConfirm"),
        },
        { name: t("template.progressDone") },
      ],
    });

    const { resetState } = useContract();
    const isMobile = inject("isMobile");
    const router = useRouter();
    const route  = useRoute();
    const store = useStore();

    const contractInformation = store.getters["template/getTemplateInfo"];

    const canSign = computed(() => route.query.contractId && route.query.canSign === "true")

    const returnTemplateList = () => {
      router.push({
        name: "Folder",
        query: {
          folderId: contractInformation.folderId,
          folderTitle: contractInformation.folderName,
        },
      });
    };

    const createCleanContract = async () => {
      await resetState();
      router.push({ name: "Contract" });
    };

    const createAnotherContractAsCopy = () => {
      router.push({ name: "Contract" });
    };

    const navigateToSign = () => {
      if(!route.query.contractId) return;
      router.push({ name: "SignDetails", query: {
        contractId: route.query.contractId
      } });
    }
    return {
      ...toRefs(state),
      isMobile,
      returnTemplateList,
      createAnotherContractAsCopy,
      createCleanContract,
      resetState,
      navigateToSign,
      canSign,
      contractInformation,
    };
  },
  beforeRouteLeave(to, from, next) {
    if (to.path.includes("/contract/summary")) {
      next({
        name: "ErrorPage",
        query: {
          error: this.$t("errors.errorBackToCreateAgain"),
        },
      });
    } else if (!to.path.includes("/contract")) {
      this.resetState();
    }
    next();
  },
});
